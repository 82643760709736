var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: _vm.popupParam.workName,
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          gridHeight: "600px",
          usePaging: false,
          columnSetting: false,
        },
        on: { linkClick: _vm.linkClick },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "workEndFlag"
                  ? [
                      _c(
                        "q-chip",
                        {
                          attrs: {
                            color: _vm.getColors(props.row.workEndFlag),
                            "text-color": "white",
                            clickable: true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDetail(props.row)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(props.row.workEndFlagName) + " ")]
                      ),
                    ]
                  : _vm._e(),
                col.name === "userLists"
                  ? [
                      _c("c-tag", {
                        attrs: {
                          isAdd: false,
                          editable: _vm.editable,
                          icon: "person_outline",
                          itemText: "workUserName",
                          itemValue: "check",
                          name: "userLists",
                        },
                        model: {
                          value: props.row.userLists,
                          callback: function ($$v) {
                            _vm.$set(props.row, "userLists", $$v)
                          },
                          expression: "props.row.userLists",
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }